<template>
    <div class="clientcabin clientcabin-blog">
        <v-container class="black--text">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/keyword-tools/1.png"
                        width="100%"
                    />
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        ClientCabin Blog Post 6: Keyword Tools Are Keeping You
                        Poor, Stop Using Them
                    </p>
                    <p>
                        Here’s a badly kept secret… I haven’t used a keyword
                        tool to get traffic in 10+ years. I think they turn you
                        poor, lazy, and stupid.
                    </p>
                    <p>
                        Alright, maybe that’s a touch extreme 🙂 but read on and
                        you’ll see what I mean…
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        How Keyword Tools Work…
                    </p>
                    <p>
                        Keyword tools all work in the same kind of way, working
                        from the same basic data (i.e. Google), giving you the
                        same basic results. Some might put a clever algorithm on
                        top to justify their cost, but really… it’s all an
                        interpretation of Google data.
                    </p>
                    <p>
                        It spits out the basic searches people make and a few
                        additional qualifiers.
                    </p>
                    <ul class="mb-4">
                        <li>iPhone…</li>
                        <li>iPhone charger…</li>
                        <li>iPhone USB charger…</li>
                        <li>best iPhone USB charger…</li>
                        <li>
                            best iPhone USB charger for pensioners… and so on.
                        </li>
                    </ul>
                    <p>
                        Consider how we’ve had ~20 years of fairly sophisticated
                        internet marketing by now. Do you really think the
                        massive multi-billion dollar data and marketing
                        corporations WOULDN’T have figured out how to buy up all
                        the obvious online real estate by now?
                    </p>
                    <p>Spoiler; they did… 10 years ago.</p>
                    <p class="text-h4 mt-8 text-center">
                        How “Demand Media” Stole The Internet…
                    </p>
                    <p>Ever heard of Livestrong? Or eHow?</p>
                    <p>
                        Almost everyone has, because they’ve been appearing for
                        all kinds of searches for years. These sites (and
                        several others) were owned by Demand Media, now ‘Leaf
                        Group’, which was the first company to ‘scale up’
                        long-tail keyword ranking.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/keyword-tools/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Demand media used an aggressive content scaling strategy
                        to publish thousands of pieces of content per day.
                    </p>
                    <p class="font-weight-bold">Their method was simple…</p>
                    <ol class="mb-4">
                        <li>Generate the biggest keyword list possible</li>
                        <li>Pay $15-$20 for an article</li>
                        <li>Publish</li>
                        <li>
                            Turn millions of trickles of traffic into an ocean
                        </li>
                        <li>Monetize with ad revenue</li>
                    </ol>
                    <p>
                        Sounds simple enough… but when we say ‘at scale’ we’re
                        talking about publishing 5,700 pieces of content PER
                        DAY.
                    </p>
                    <p>
                        We know long-tail searches make up for upwards of 90% of
                        all searches made in total. So they bought up the
                        market. Until Google realised the content was weak…
                        rolled out the Panda update, at least.
                    </p>
                    <p>
                        So ‘Content Farming’ isn’t like it once was… but ‘Leaf’
                        are still going strong… strong enough to have recently
                        been bought out for $323,000,000. Which means someone
                        else thinks there’s more than $323m of value to be
                        gained with their setup.
                    </p>
                    <p class="text-h4 mt-8 text-center">Robots vs. Humans…</p>
                    <p>
                        Much like how you wouldn’t trust a GPS to plan a nice
                        ocean-side drive, visiting the best eating spots, to
                        take in the most stunning scenery…
                    </p>
                    <p>
                        … you shouldn’t trust a robot to do your keyword
                        research.
                    </p>
                    <p>For one simple reason.</p>
                    <p>
                        You might get to your destination, on paper, and it
                        might be efficient… But something gets lost when you
                        remove ‘the human touch’ and trust in data blindly.
                    </p>
                    <p>… got 114,000 searches per month? Awesome!</p>
                    <p>… costs $6.20 per click? Amazing!</p>
                    <p>Sounds like a hot keyword!</p>
                    <p>
                        Sure, hot enough everyone in the Fortune 500 is already
                        after it. Or maybe Google decided it wants to keep you
                        on Google and serves up a ‘snippet’ search result from
                        its Knowledge Graph instead.
                    </p>
                    <p>
                        Then again… is that keyword really going to give you the
                        highest return on your effort? Is it more or less likely
                        to result in an action or a sale?
                    </p>
                    <p>
                        These things are important to consider, which a keyword
                        tool does not. Blindly ranking for as many keywords as
                        you can (especially when it’s the same keywords everyone
                        else is after) is generally a bad idea, because
                    </p>
                    <p>a) you’ll fail, and…</p>
                    <p>b) It won’t make as much money as it could.</p>
                    <p class="text-h4 mt-8 text-center">
                        The Reality Of Your Competition…
                    </p>
                    <p>
                        When you’re using a keyword tool, you’re putting
                        yourself up against 3 main types of competitors.
                    </p>
                    <ul class="mb-4">
                        <li>Mega-Corporations</li>
                        <li>SEO Agencies</li>
                        <li>Black Hatters</li>
                    </ul>
                    <p>
                        Mega-Corporations, perhaps surprisingly, take the most
                        human approach, because human sweat is valuable (read:
                        expensive) and they have the money to hire professional
                        content teams, to produce high production value content
                        and market that content, to trigger whatever algorithms
                        they need to, to get the attention they want. This
                        includes Amazon and even Google itself.
                    </p>
                    <p>
                        SEO Agencies are sometimes really, really good at SEO –
                        and they generate thousands to hundreds of thousands
                        (sometimes millions) per month to fund what they need to
                        get the rankings they (i.e. their clients) desire.
                        Generally there’s a mixture here of human and
                        automation. I can appreciate this.
                    </p>
                    <p>
                        Black Hatters are obsessed with ‘gaming the system’ and
                        quite often manage to do so, using whatever technology
                        they have at their disposal. Incidentally, this is the
                        most robotic, automated approach.
                    </p>
                    <p>
                        In each case, you’re going up against someone you
                        probably can’t beat. So, what to do?
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/keyword-tools/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Your potential audience extends far beyond what you
                        imagine - you just have to bring your business in front
                        of them by developing content that answers the very
                        specific questions they ask
                    </p>
                    <p>
                        We suggest using our method to discover the low
                        competition, high value keywords nobody else is looking
                        for!
                    </p>
                    <p>Do things smarter. Scale from there.</p>
                    <p class="text-h4 mt-8 text-center">
                        Fine, But Does It Really Work?
                    </p>
                    <p>
                        What I mean by free– is if I were to PAY for that
                        traffic through pay-per-click advertising, I’d be
                        charged roughly $5 per click, because it’s a
                        competitive, commercial niche.
                    </p>
                    <p>
                        We essentially have projects we’ve barely touched for
                        years…
                    </p>
                    <p>
                        … still generating free, high-quality traffic every
                        single day.
                    </p>
                    <p>
                        We do this across sectors and we’re able to BEAT
                        mega-corporations with it…
                    </p>

                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/keyword-tools/4.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Using our method, smaller players have a chance to
                        outrank major competitors in their areas - in this case,
                        grabbing the “featured snippet” position on Google in a
                        highly desirable niche.
                    </p>

                    <p>It works GREAT for ANY niche.</p>
                    <p>It works GREAT for beginners.</p>
                    <p>It works GREAT for eCommerce.</p>
                    <p>It works GREAT for local businesses.</p>
                    <p>It works GREAT for affiliate marketing.</p>
                    <p>
                        … and we can do it for you if you decide to
                        <router-link to="/info" class="font-weight-bold">
                            join {{ company }}
                        </router-link>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class KeywordTools extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>
